<template>
  <v-dialog v-model="dialog" persistent max-width="70%" scrollable>
    <!-- <app-loader v-model="isLoading"></app-loader> -->
    <template v-slot:activator="{ on }">
      <app-add-button v-bind:module="'dollarrate'"></app-add-button>
    </template>
    <v-form
        ref="form"
        v-model="isvalid"
        lazy-validation
      >
      <v-card title>
        <v-toolbar
        flat
        dark
        color="primary"
      >
        <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
        <div class="flex-grow-1"></div>
        <v-btn 
          icon
          dark
          @click="close"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm6 md4>                    
              <v-select
                v-model="editedItem.fiscal_year"
                :items="years"
                :rules="[v => !!v || 'Please select Fiscal Year']"
                label="Fiscal Year"
                data-vv-name="fiscalYear"
                required
            ></v-select>
            </v-flex>
            <v-flex xs12 sm6 md4>
            <v-select
                v-model="editedItem.period_no"
                :items="periodNo"
                :rules="[v => !!v || 'Please select Period No']"
                label="Period No"
                data-vv-name="periodNo"
                required
            ></v-select>
            </v-flex>
            <v-flex xs12 sm6 md4>
              <v-select
                  v-model="editedItem.acct_class"
                  :items="accountClass"
                  :rules="[v => !!v || 'Please select account class']"
                  label="Account Class"
                  data-vv-name="accountClass"
                  required
              ></v-select>
            </v-flex>
            <v-flex xs12 sm6 md4>
              <v-select
                  v-model="editedItem.currency_id"
                  :items="currency"
                  :rules="[v => !!v || 'Please select currency code']"
                  label="Currency Code"
                  item-value="id"
                  item-text="cur_desc"
                  required
              ></v-select>
            </v-flex>
            <v-flex xs12 sm6 md4>
              <v-text-field 
                  v-model="editedItem.dolr_rate"
                :counter="7"
                :rules="dolr_rateRules"
                label="Currency Rate"
                required
                maxlength=7
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" dark @click="save">Save</v-btn>
          <v-btn color="red darken-1" text @click="close">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import AppAddButtonVue from '../../../partials/AppAddButton.vue';

export default {
	components: {
		'app-add-button': AppAddButtonVue
	},
	data: () => ({
		search: null,
		isvalid: true,
		fiscal_yearRules: [
			v => !!v || 'Fiscal Year is required.',
			v => (v && v.length < 5) || 'Fiscal Year must be less than 5 numbers',
			v =>  /^\d{4}$/.test(v) || 'Must be 4 digits',
		],
		period_noRules: [
			v => !!v || 'Period No is required.',
			v => (v && v.length <= 2) || 'Period No must be less than 2 numbers',
		],
		dolr_rateRules: [
			v => !!v || 'Dollar Rate is required.',
			v => /^\d+(\.\d{1,4})?$/.test(v) || 'Must be 4 digits',
		],
		years: []
	}),

	computed: {
		...mapGetters({
			dollarrates: 'dollarrate/dollarrates',
			fiscalYear: 'dollarrate/fiscalYear',
			periodNo: 'dollarrate/periodNo',
			currency: 'currency/currencies',
			accountClass: 'dollarrate/accountClass',
			editedItem: 'dollarrate/editedItem',
			defaultItem: 'dollarrate/defaultItem',
			dialog: 'dollarrate/dialog',
			editedIndex: 'dollarrate/editedIndex',
			formTitle: 'dollarrate/formTitle',
			valid: 'dollarrate/valid',
			isLoading: 'app/isLoading',
			currUser: 'auth/currUser'
		}),
	}, 
	mounted() {
		this.$store.dispatch('currency/getCurrencies');
    
		let beg_year = '2022';
		let end_year = new Date().getFullYear() + 1;
		for (var i = end_year; i >= beg_year; i--) {
			this.years.push(i);
		}
	},
	methods: {
		close () {
			setTimeout(() => {
				this.$refs.form.reset();
				this.$refs.form.resetValidation();
				this.$store.dispatch('dollarrate/setValid',true);
				this.$store.dispatch('dollarrate/setDialog',false);
				this.$store.dispatch('dollarrate/setEditedIndex','-1');
				this.isvalid = false;
			}, 2000);
		},
		save () {
			this.$store.dispatch('dollarrate/setValid',this.$refs.form.validate());
			this.isvalid = this.$refs.form.validate();
			this.editedItem.edit_by = this.currUser.id;
			delete this.editedItem.created_at;
			delete this.editedItem.updated_at;
			if(this.valid){
				if (this.editedIndex > -1) {
					this.$store.dispatch('dollarrate/updateDollarrate',this.editedItem);
				} else {
					this.$store.dispatch('dollarrate/saveDollarrate',this.editedItem);
				}
				setTimeout(() => {
					this.close();
				}, 
				3000);
			}
		},
	}
};
</script>
