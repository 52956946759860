<template>
  <div>
  <v-data-table
    :headers="headers"
    :items="filteredItems"
    sort-by="period_no"
    class="elevation-1"
    dense
    :search="search"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Currency Rate File Maintenance</v-toolbar-title>
        <v-spacer></v-spacer>
		<v-layout wrap>
			<v-flex md3 class="year">
				<v-autocomplete
					v-model="year"
					:items="years"
					editable
					dense
					label="Year"
					hide-details
				></v-autocomplete>
			</v-flex>
			<v-flex md8 class="search">
				<v-text-field
					v-model="search"
					append-icon="search"
					label="Search"
					single-line
					hide-details
				></v-text-field>
			</v-flex>
			<v-flex md1>
				<vue-excel-xlsx
					:data="filteredItems"
					:columns="columnHeaders"
					:file-name="module_name"
					>
					<v-btn icon alt class="mt-2" :color="filteredItems.length=== 0 ? 'gray' : 'primary'" :disabled="filteredItems.length===0"><v-icon>mdi-arrow-down-bold-circle</v-icon> </v-btn>
				</vue-excel-xlsx>
			</v-flex>
		</v-layout>
      </v-toolbar>
    </template>
    <template v-slot:item.action="{ item }">
					<v-icon
					color="grey"
					class="mr-2"
					small
					v-if="role_access < 3"
				>
					mdi-pencil-off
				</v-icon>
				<v-icon
					color="primary"
					v-else
					class="mr-2"
					small
					@click="editItem(item)"
				>
					edit
				</v-icon>
					<v-icon
					color="grey"
					class="mr-2"
					small
					v-if="role_access < 4"
				>
					mdi-delete-off
				</v-icon>
				<v-icon
					small
					v-else
					color="red"
					@click="deleteItem(item)"
				>
					delete
				</v-icon>
			</template>
  </v-data-table>
  <create-dollarrate v-if="role_access > 1"></create-dollarrate>
  </div>
</template>

<script>
import createDollarrate from './createDollarrate.vue';
import { mapGetters } from 'vuex';
import { format, getYear, parseISO } from 'date-fns';

export default {
	components: {
		'create-dollarrate': createDollarrate
	},
	data(){
		return{
			module_name: 'Currency Rates',
			role_access: 1,
			search: '',
			data: [],
			nextPage: '',
			years: [],
			year: getYear(parseISO(format(new Date(), 'yyyy-MM-dd')))
		};
	},
	watch: {
		dollarrates: {
			handler(){
				this.getData();
			}
		}
	},
	computed: {
		...mapGetters({
			dollarrates: 'dollarrate/dollarrates',
			headers: 'dollarrate/headers',
			columnHeaders: 'dollarrate/columnHeaders',
			editedItem: 'dollarrate/editedItem',
			defaultItem: 'dollarrate/defaultItem',
			dialog: 'dollarrate/dialog',
			editedIndex: 'dollarrate/editedIndex',
			valid: 'dollarrate/valid',
			fiscalYear: 'acctperiodmain/fiscalYear',
			currUser: 'auth/currUser'
		}),
		filteredItems(){
			let filteredItem = [];
			let data = this.data;
			data.forEach(element => {
				if(element.fiscal_year == this.year){
					element.period = this.getMonth(element.period_no);
					filteredItem.push(element);
				}
			});
			return filteredItem;
		}
	},
	mounted () {
		this.$store.dispatch('dollarrate/getDollarrates');

		let roles = this.currUser.roleaccess;
		if(roles.length > 0){
			let roleaccess = roles.filter(r => r.module_name === this.module_name);
			if(roleaccess.length > 0){
				this.role_access = roleaccess[0].access_level;
			}
		}
		let beg_year = '2022';
		let end_year = new Date().getFullYear() + 1;
		for (var i = end_year; i >= beg_year; i--) {
			this.years.push(i );
		}
	},
	methods: {
		editItem (item) {
			this.$store.dispatch('dollarrate/setEditedIndex',this.dollarrates.data.indexOf(item));
			this.$store.dispatch('dollarrate/getDollarDetail',item);
			// this.$store.dispatch('dollarrate/setEditedItems',Object.assign({}, item));
			this.$store.dispatch('dollarrate/setDialog',true);
			this.$store.dispatch('dollarrate/setValid',true);
			this.$store.dispatch('dollarrate/setformTitle','Edit Entry');
		},
		deleteItem (item) {
			this.$swal.fire({
				title: 'Delete ' + item.fiscal_year + '(' + item.period_no + ')' + '(' + item.period_no + ')' + '?',
				text: 'You won\'t be able to revert this!',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch('dollarrate/setisLoading',true);
					this.$store.dispatch('dollarrate/deleteDollarrate',item);
				}
			});
		},
		changeNextData() {
			var nextPage = this.dollarrates.current_page + 1;
			this.$store.dispatch('dollarrate/getDataPages', nextPage);
		},

		changePrevData() {
			var prevPage = this.dollarrates.current_page - 1;
			this.$store.dispatch('dollarrate/getDataPages', prevPage);
		},
		async getData(){
			this.data = this.dollarrates.data;
			this.nextPage = this.dollarrates.next_page_url;
			let page = 2;

			while (this.nextPage !== null){
				await this.$store.dispatch('dollarrate/getData', page).then(response => {
					this.nextPage = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.data.push(details);
					});
				});
				page++;
			}
		},
		getMonth(period){
			switch(period){
			case 1:
				return 'January';
			case 2:
				return 'February';
			case 3:
				return 'March';
			case 4:
				return 'April';
			case 5:
				return 'May';
			case 6:
				return 'June';
			case 7:
				return 'July';
			case 8:
				return 'August';
			case 9:
				return 'September';
			case 10:
				return 'October';
			case 11:
				return 'November';
			default:
				return 'December';
			}
		}
	}
};
</script>
<style scoped>
	.year {
		margin-top: 16px;
		padding-right: 10px;
	}
	.search {
		margin-top: 10px;
	}
</style>